.facet-browse-container {
	border-radius: var(--btn-border-radius-base);
	padding: 16px;
	position: relative;
	border: 1px solid var(--input-border-color);

	&.border-radius-none {
		border-radius: 0;
	}

	.facet-select {
		position: relative;
		border: 1px solid transparent;

		.value {
			position: relative;
		}

		button,
		a[role='button'] {
			color: var(--input-color);
			padding-left: 13px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			background-color: transparent;
		}

		button[role='combobox'] {
			padding-right: 37.5px !important;
			-webkit-appearance: none;
			background-image: none !important;

			&:not(:focus-visible) {
				outline: none;
			}
		}

		i {
			position: absolute;
			top: 50%;
			right: 13px;
			font-size: 17.5px;
			transform: translateY(-50%);
		}

		button.all-filter-button:not(:focus-visible) {
			outline: none !important;
		}

		&.has-focus {
			border-top: 1px solid var(--input-border-color);
			border-right: 1px solid var(--input-border-color);
			border-left: 1px solid var(--input-border-color);

			div.value {
				box-shadow: 0 0 8px rgba(0, 0, 0, 0.24);
				background-color: var(--input-bg);

				button[role='combobox'] {
					&:focus-visible {
						outline: none !important;
					}
				}

				div.select-options {
					box-shadow: 0 8px 8px rgba(0, 0, 0, 0.24);
				}
			}
		}

		&.has-focus .input {
			background-color: var(--color-neutral-0);
		}

		.select-options {
			position: absolute;
			z-index: 1000;
			right: 0;
			top: calc(100% - 5px);
			left: -1px;
			border-radius: 0 0 3px 3px;
			overflow: auto;
			max-height: 360px;
			background-color: var(--input-bg);
			border-bottom: 1px solid var(--input-border-color);
			border-right: 1px solid var(--input-border-color);
			border-left: 1px solid var(--input-border-color);
			width: calc(100% + 2px);

			.options {
				list-style: none;
				padding: 0;
				margin: 0;

				.option {
					display: block;
					width: 100%;
					padding: 16px 16px;
					border: none;
					outline: none;
					font-size: 14px;
					text-align: left;
					background: inherit;
					white-space: break-spaces;

					&.is-selected {
						color: var(--color-primary-300);
						background-color: var(--container-contrast-low-bg);
					}

					&.is-highlighted,
					&:hover {
						color: var(--color-primary-300);
						text-decoration: underline;
						background-color: var(--container-contrast-low-bg);
					}

					small {
						color: var(--input-color);
						opacity: 0.6;
					}
				}
			}
		}

		.mobile-select-box,
		.mobile-select-box + .select-options .options .option {
			min-height: 41px;
			padding: 9px 9px 9px 13px;
		}
	}

	.facet-controller,
	.form-group {
		.facet-select-border-right,
		.facet-select-border-bottom,
		.facet-select-border-left {
			position: absolute;
			background-color: var(--container-contrast-max-bg);
			opacity: 0.1;
			visibility: hidden;
		}

		.facet-select-border-right {
			width: 1px;
			height: 100%;
			right: 0;
			top: 0;
		}

		.facet-select-border-bottom {
			width: 100%;
			height: 1px;
			bottom: 0;
			left: 0;
		}

		.facet-select-border-left {
			width: 1px;
			height: 100%;
			left: 0;
			top: 0;
		}

		&.right-border {
			.facet-select-border-right {
				visibility: visible;
			}
		}

		&.left-border {
			.facet-select-border-left {
				visibility: visible;
			}
		}

		&.bottom-border {
			.facet-select-border-bottom {
				visibility: visible;
			}
		}

		&.disable-border {
			border-bottom: none;

			&.right-border .facet-select {
				border-right: none;
			}
		}
	}

	.facet-controller,
	.form-group:not([class*='col-']) {
		position: relative;
		margin-bottom: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.all-filter-button[type='button'] {
		padding-right: 37.5px !important;
		background-image: none !important;

		i {
			position: absolute;
			top: 50%;
			right: 13px;
			font-size: 17.5px;
			transform: translateY(-50%);
		}
	}

	.facet-select .value input:focus {
		background-color: var(--color-neutral-0);
		outline: none !important;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.24);
	}
}
