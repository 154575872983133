[data-widget-name='ws-facet-browse'] {
	.sr-only {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		white-space: nowrap;
		border-width: 0;
	}

	.sub-heading {
		text-align: center;
		margin-bottom: 16px;
	}

	.inline,
	.row {
		.form-group {
			.facet-select {
				.value {
					button[role='combobox'] {
						width: 175px;
					}
				}
			}
		}

		.facet-controller {
			.all-filter-button {
				min-width: 175px;
			}
		}
	}

	.default,
	.two-column {
		.form-group {
			&.all-filter {
				min-width: 50%;
				flex: 1 1 50%;
			}

			.facet-select {
				width: 100%;
			}
		}
	}

	.facet-browse-background {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: var(--input-bg);
		border-radius: var(--btn-border-radius-base);
	}

	.facet-browse-container {
		transition: background-color 0.5s cubic-bezier(0.5, 0, 0, 0.5);

		&.desktop {
			.facet-browse-background {
				opacity: 0.9;
			}
		}

		&.mobile {
			.facet-browse-background {
				opacity: 1;
			}
		}

		&.desktop:hover {
			.facet-browse-background {
				opacity: 1;
			}
		}

		&.mobile.mobile-hover-bg {
			.facet-browse-background {
				opacity: 0.9;
			}
		}

		&.bg-alpha-none {
			&.desktop {
				.facet-browse-background {
					opacity: 1;
				}
			}
		}
	}

	.default {
		.form-group.right-border .facet-select {
			border-right: none;
		}
	}
}

.ws-facet-browse-modal-backdrop {
	z-index: 2147483657 !important;
}

div#ws-facet-browse-modal {
	div.modal-content {
		box-shadow: none;
	}
}

.facet-dropdown-container {
	position: relative;
}

.facet-dropdown-container,
.facet-filter-modal {
	opacity: 1;
	transition: opacity 0.25s cubic-bezier(0, 0.5, 0.5, 1);

	.facet-list-group {
		background-color: transparent;
		border: none;

		.panel-collapse {
			border: none;

			.panel-body {
				padding: 0;
			}
		}
	}

	&.modal-body {
		.facet-list-group {
			ol {
				li {
					flex-basis: calc(50% - 4px);
				}

				.facet-list-facet-label-text-container {
					display: block;
				}
			}
		}
	}
}

.data-loading-form {
	.facet-dropdown-container {
		opacity: 0.3;
		transition-delay: 0.5s;
	}

	.loading-container,
	.data-loading {
		visibility: visible;
		transition-delay: 0.5s;
		opacity: 1;
	}
}

.data-loading-modal {
	.facet-filter-modal {
		opacity: 0.3;
		transition-delay: 0.5s;
	}

	.loading-container,
	.data-loading {
		visibility: visible;
		transition-delay: 0.5s;
		opacity: 1;
	}
}
