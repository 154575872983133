.loading-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	border-radius: 16px;
	z-index: 99;
	visibility: hidden;

	&.modal-loading {
		border-radius: 0;
	}

	.data-loading {
		position: absolute;
		margin: 0;
		visibility: hidden;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;

		.bar {
			width: 5px;
			height: 30px;
			background: transparent;
			display: inline-block;
			transform-origin: bottom center;
			border-top-right-radius: 20px;
			border-top-left-radius: 20px;
			animation: loader 0.9s linear infinite;
		}

		.bar-1 {
			animation-delay: 0.1s;
		}

		.bar-2 {
			animation-delay: 0.2s;
		}

		.bar-3 {
			animation-delay: 0.3s;
		}

		.bar-4 {
			animation-delay: 0.4s;
		}

		.bar-5 {
			animation-delay: 0.5s;
		}

		.bar-6 {
			animation-delay: 0.6s;
		}

		.bar-7 {
			animation-delay: 0.7s;
		}

		.bar-8 {
			animation-delay: 0.8s;
		}

		@keyframes loader {
			0% {
				transform: scaleY(0.1);
				background: transparent;
			}

			50% {
				transform: scaleY(1);
				background: rgb(136, 135, 135);
			}

			100% {
				transform: scaleY(0.3);
				background: rgb(0, 0, 0);
			}
		}
	}
}
